var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('base-header',{attrs:{"title":_vm.$t('app.jugadores.name'),"link":"Dashboard"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"flex-grow-1 flex-grow-0"},[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.jugadores.title'))+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.jugadores.subtitle'))+" ")])]),_c('v-col',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.openPrintPlayers}},[_c('v-icon',[_vm._v(" mdi-file-excel-box ")]),_vm._v("   Descargar datos para credenciales ")],1),_c('div',{staticStyle:{"display":"none"}},[(_vm.excelForCredentials.length > 0 && _vm.excelForCredentials[0].data.length > 0)?_c('xlsx-workbook',[_vm._l((_vm.excelForCredentials),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":sheet.data,"sheet-name":sheet.name}})}),_c('xlsx-download',{attrs:{"filename":_vm.excelCredencialesName,"disable-wrapper-click":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var download = ref.download;
return [_c('button',{ref:"downloadSingleCredential",on:{"click":download}},[_vm._v("Download with slot scope")])]}}],null,false,4136073764)})],2):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.openItemAdd}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" ")],1)],1)],1)]},proxy:true}])},[_c('v-card-title',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('app.buttons.search'),"rounded":"","outlined":"","dense":"","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.filters.selected.query),callback:function ($$v) {_vm.$set(_vm.filters.selected, "query", $$v)},expression:"filters.selected.query"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-switch',{attrs:{"label":_vm.$t('app.jugadores.show_players_with_credential')},model:{value:(_vm.showPlayersWithCredential),callback:function ($$v) {_vm.showPlayersWithCredential=$$v},expression:"showPlayersWithCredential"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":_vm.$t('app.jugadores.show_players_with_credential_photo')},model:{value:(_vm.showPlayersWithCredentialAndPhoto),callback:function ($$v) {_vm.showPlayersWithCredentialAndPhoto=$$v},expression:"showPlayersWithCredentialAndPhoto"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":10,"search":_vm.filters.selected.query,"custom-filter":_vm.customFilter},scopedSlots:_vm._u([{key:"item.identificacion_verificada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.identificacion_verificada ? _vm.$t('app.general.yes') : _vm.$t('app.general.no'))+" ")]}},{key:"item.credencial_activa",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.credencial_activa ? _vm.$t('app.general.yes') : _vm.$t('app.general.no'))+" ")])]}},{key:"item.semaforo_credencial",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"justify-center"},[_c('v-chip',{attrs:{"text-color":item.semaforo_credencial.text,"color":item.semaforo_credencial.bg,"small":""}},[(item.credencial_activa)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.fecha_pago_credencial,'add', '1 year', 'MMMM Do YYYY'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('app.general.no_credential'))+" ")]],2)],1)]}},{key:"item.equipo",fn:function(ref){
var item = ref.item;
return [_vm._l((item.equipo),function(x,index){return [_c('v-chip',{key:("team-" + index),attrs:{"label":""}},[_vm._v(" "+_vm._s(x.nombre)+" ")])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.credencial_activa && item.persona.foto != null && item.persona.foto != '')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setCurrentSelectedPlayer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-download ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('app.jugadores.download_for_credential'))+" ")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemDetail(item)}}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1),(!item.credencial_activa)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openPagoCredencial(item)}}},[_c('v-icon',[_vm._v(" mdi-cash ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemAddToTeamEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-account-group ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemDelete(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}}])})],1)],1)],1),_c('jugadores-add'),_c('jugadores-edit'),_c('jugadores-detail'),_c('jugadores-delete'),_c('jugadores-add-to-team-question'),_c('jugadores-add-to-team'),_c('jugadores-add-to-team-edit'),_c('pago-add'),_c('jugadores-imprimir-credenciales')],1)}
var staticRenderFns = []

export { render, staticRenderFns }