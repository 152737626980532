<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.jugadores.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.jugadores.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.jugadores.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  text
                  @click="openPrintPlayers"
                >
                  <v-icon>
                    mdi-file-excel-box
                  </v-icon>
                  &nbsp;
                  Descargar datos para credenciales
                </v-btn>

                <div style="display: none">
                  <xlsx-workbook v-if="excelForCredentials.length > 0 && excelForCredentials[0].data.length > 0">
                    <xlsx-sheet
                      v-for="sheet in excelForCredentials"
                      :key="sheet.name"
                      :collection="sheet.data"
                      :sheet-name="sheet.name"
                    />
                    <xlsx-download
                      :filename="excelCredencialesName"
                      disable-wrapper-click
                    >
                      <template #default="{download}">
                        <button ref="downloadSingleCredential"  @click="download">Download with slot scope</button>
                      </template>
                    </xlsx-download>
                  </xlsx-workbook>
                </div>
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    v-model="filters.selected.query"
                    :label="$t('app.buttons.search')"
                    rounded
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-switch
                    v-model="showPlayersWithCredential"
                    :label="$t('app.jugadores.show_players_with_credential')"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    v-model="showPlayersWithCredentialAndPhoto"
                    :label="$t('app.jugadores.show_players_with_credential_photo')"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="filters.selected.query"
            :custom-filter="customFilter"
          >
            <template v-slot:item.identificacion_verificada="{ item }">
              {{ item.identificacion_verificada ? $t('app.general.yes') : $t('app.general.no') }}
            </template>
            <template v-slot:item.credencial_activa="{ item }">
              <v-chip
                label
                text-color="white"
              >
                {{ item.credencial_activa ? $t('app.general.yes') : $t('app.general.no') }}
              </v-chip>
            </template>
            <template v-slot:item.semaforo_credencial="{ item }">
              <v-col class="justify-center">
                <v-chip
                  :text-color="item.semaforo_credencial.text"
                  :color="item.semaforo_credencial.bg"
                  small
                >
                  <template v-if="item.credencial_activa">
                    {{ item.fecha_pago_credencial | moment('add', '1 year', 'MMMM Do YYYY') }}
                  </template>
                  <template v-else>
                    {{ $t('app.general.no_credential') }}
                  </template>
                </v-chip>
              </v-col>
            </template>
            <template v-slot:item.equipo="{ item }">
              <template v-for="(x, index) in item.equipo">
                <v-chip
                  :key="`team-${index}`"
                  label
                >
                  {{ x.nombre }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      v-if="item.credencial_activa && item.persona.foto != null && item.persona.foto != ''"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="setCurrentSelectedPlayer(item)"
                    >
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('app.jugadores.download_for_credential') }}
                  </span>
                </v-tooltip>

                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="!item.credencial_activa"
                  icon
                  @click="openPagoCredencial(item)"
                >
                  <v-icon>
                    mdi-cash
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemAddToTeamEdit(item)"
                >
                  <v-icon>
                    mdi-account-group
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <jugadores-add />
    <jugadores-edit />
    <jugadores-detail />
    <jugadores-delete />
    <jugadores-add-to-team-question />
    <jugadores-add-to-team />
    <jugadores-add-to-team-edit />
    <pago-add />
    <jugadores-imprimir-credenciales />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  import { mapMutations } from 'vuex'
  import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'Jugadores',
    mixins: [customFilter],
    components: {
      JugadoresAdd: () => import('./JugadoresAdd'),
      JugadoresEdit: () => import('./JugadoresEdit'),
      JugadoresDetail: () => import('./JugadoresDetail'),
      JugadoresDelete: () => import('./JugadoresDelete'),
      JugadoresAddToTeamQuestion: () => import('./JugadoresAddToTeamQuestion'),
      JugadoresAddToTeam: () => import('./JugadoresAddToTeam'),
      JugadoresAddToTeamEdit: () => import('./JugadoresAddToTeamEdit'),
      PagoAdd: () => import('../pagos/PagoAdd'),
      JugadoresImprimirCredenciales: () => import('./JugadoresImprimirCredenciales'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.code'),
            value: 'alphacode',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'persona.fullname',
          },
          {
            text: this.$t('app.equipos.name'),
            value: 'equipo',
          },
          {
            text: this.$t('app.headers.responsible_name'),
            value: 'nombre_persona_responsable',
          },
          {
            text: this.$t('app.headers.weight'),
            value: 'peso',
          },
          {
            text: this.$t('app.headers.height'),
            value: 'estatura',
          },
          {
            text: this.$t('app.headers.id_type'),
            value: 'tipo_identificacion.nombre',
          },
          {
            text: this.$t('app.headers.id_number'),
            value: 'numero_identificacion',
          },
          {
            text: this.$t('app.headers.id_verified'),
            value: 'identificacion_verificada',
          },
          {
            text: this.$t('app.headers.has_credential'),
            value: 'credencial_activa',
          },
          {
            text: this.$t('app.headers.credential_expire'),
            value: 'semaforo_credencial',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          selected: {
            query: '',
          },
        },
        excel: [],
        excelName: this.$t('app.jugadores.title') + ' - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelCredencialesName: this.$t('app.jugadores.title') + ' para Credenciales - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelHiddenColumns: [
          'id',
          'alphacode',
        ],
        itemsSelected: [],
        showPlayersWithCredential: false,
        showPlayersWithCredentialAndPhoto: false,
      }
    },
    computed: {
      excelForCredentials () {
        return [{
          name: this.$t('app.general.report'),
          data: this.itemsSelected.map(item => {
            return {
              'ID Number': item.alphacode.replace('NF', ''),
              'First Name': item.persona.nombre,
              'Last Name': item.persona.apellido,
              'Expiration Date': this.$moment(item.fecha_pago_credencial).add(1, 'y').format('L'),
              Photo: item.persona.foto ? item.persona.foto.replace('fotos/', '') : '',
            }
          })
        }]
      },
      filteredItems () {
        let items = this.items
        if (this.showPlayersWithCredential || this.showPlayersWithCredentialAndPhoto) {
          if (this.showPlayersWithCredential) {
            items = this.items.filter(item => item.credencial_activa)
          }

          if (this.showPlayersWithCredentialAndPhoto) {
            items = this.items.filter(item => item.credencial_activa && item.persona.foto != null && item.persona.foto != '')
          }
        }

        return items
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      ...mapMutations({
        setAppData: 'SET_APP_DATA',
      }),
      async urlDownloadPhotos () {
        let params = {
          ids: this.itemsSelected.map(item => item.id)
        }

        await this.$http.post(route('v1/player/photos'), params)
          .then(response => {
            if (response.body.code === 200) {
              window.open(response.body.data.url)
            } else {
              this.processError(response.body)
            }
          }, error => this.processError(error))
      },
      setCurrentSelectedPlayer (player) {
        this.itemsSelected = [player]
        setTimeout(() => {
          this.$refs.downloadSingleCredential.click()
          setTimeout(() => {
            this.urlDownloadPhotos()
          }, 500)
        }, 500)
      },
      async init () {
        this.toggleLoader()

        this.items = await this.getJugadores()
        //const usuarios = await this.getUsuarios()

        /*this.setAppData({
          usuarios,
        })*/

        this.excel = [
          {
            name: this.$t('app.general.report'),
            data: this.prepareForExcel(this.items, this.headers, this.excelHiddenColumns),
          },
        ]

        /*this.excelForCredentials = [{
          name: this.$t('app.general.report'),
          data: this.items.filter(item => item.credencial_activa).map(item => {
            const aux = {
              'ID Number': item.alphacode.replace('NF', ''),
              'First Name': item.persona.nombre,
              'Last Name': item.persona.apellido,
              'Expiration Date': this.$moment(item.fecha_pago_credencial).add(1, 'y').format('L'),
              Photo: item.persona.foto ? item.persona.foto.replace('fotos/', '') : '',
            }

            return aux
          }),
        }]*/

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('jugadores-add')
      },
      openItemEdit (item) {
        EventBus.$emit('jugadores-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('jugadores-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('jugadores-delete', item)
      },
      openItemAddToTeamEdit (item) {
        EventBus.$emit('jugadores-add-to-team-edit', {
          jugador: item,
        })
      },
      openPagoCredencial (item) {
        // EventBus.$emit('pago-credencial-open', item)
        EventBus.$emit('pago-add', {
          item: item,
          conceptoPagoId: parseInt(process.env.VUE_APP_CONCEPTO_PAGO_CREDENCIAL),
        })
      },
      openPrintPlayers () {
        EventBus.$emit('jugadores-print-credentials')
      }
    },
  }
</script>

<style scoped>

</style>
